import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import SVGSeparator from '../components/project-blocks/separator'
import InformationSectionBlock from '../components/project-blocks/information-section'
import TextSingleBlock from '../components/project-blocks/text-single'
import TextLargeAndSmallBlock from '../components/project-blocks/text-large-and-small'
import ImageSingleBlock from '../components/project-blocks/image-single'
// import ImageDoubleBlock from '../components/project-blocks/image-double'
import TextAndImageBlock from '../components/project-blocks/text-and-image'

import { informationSectionData } from '../data/case-study-data--font-book-x'

const HeaderImage = styled.div`
	height: auto;

	margin-bottom: 12px;

	& > * {
		width: auto;
		max-height: 100vh;
		/* height: fill-available; */
	}
`

const HeadingGroup = styled.header`
	display: grid;
	grid-gap: 6px;
`

const Title = styled.h1`
	font-size: 24px;
	font-weight: 500;
	letter-spacing: 1px;

	@media (min-width: 576px) {
	}

	@media (min-width: 768px) {
		font-size: 32px;
	}

	@media (min-width: 992px) {
	}

	@media (min-width: 1200px) {
	}
`

const Subtitle = styled.h2`
	font-size: 18px;
	opacity: 0.75;

	@media (min-width: 768px) {
		font-size: 21px;
	}
`

const Castle = ({ data }) => (
	<Layout>
		<SEO title="Font Book X — UX / UI Case Study" />
		<HeaderImage>
			<Img
				fluid={data.heroImage.childImageSharp.fluid}
				alt="Main Screen of the Redesigned Font Book."
				imgStyle={{ objectFit: 'cover' }}
			/>
		</HeaderImage>
		<HeadingGroup>
			<Title>Font Book X</Title>
			<Subtitle>UX/UI Case Study</Subtitle>
		</HeadingGroup>
		<SVGSeparator />
		<InformationSectionBlock data={informationSectionData} />
		<SVGSeparator />
		<TextSingleBlock
			heading={'The Problem'}
			paragraphs={[
				'Font book is a font manager made by Apple Inc. for macOS first released in 2003. Unfortunately, the app hasn’t changed a whole lot since that time. There is so much that could be done with the app now that the font world has changed; fonts are no longer just a description of bézier curves but software.',
				'This leads to the purpose of this project. This project seeks to reimagine Font Book for the modern age.'
			]}
			link={{
				url:
					'https://aaronvince.com/static/4003--design-brief-0092a3bd53e2a1369bda2bfa9b36b498.pdf',
				label: 'Full Project Brief'
			}}
		/>
		<SVGSeparator />
		<ImageSingleBlock
			imageData={data.oldFontBook}
			altText={'Apple\'s current Font Book Application.'}
		/>
		<SVGSeparator />
		<TextLargeAndSmallBlock
			heading={'The Process'}
			paragraphs={[
				'When redesigning an application like Font Book, it requires a fairly comprehensive process. Starting with research and a competitive challenge to see what\'s out there and which ideas work and which ones don\'t. Followed by rapid iteration of theoretical interfaces and experiences with pen and paper. After, a narrowing of ideas and a higher fidelity mockup is produced that is offered to others for feedback. Then, feedback is incorporated into the design with a nearly done version being tested by potential users. Lastly, the user testing is incorporated and a final prototype is made to be built by engineers.'
			]}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Competitive Analysis'}
			paragraphs={[
				'When doing the research for this app redesign, a competitive analysis was performed to assess the strengths and weaknesses of current and potential competitors in the font management landscape.',
				'I analyzed two similar apps — RightFont 5 and FontBase — and one service — Google Fonts. The two apps revealed that the ability to manage, sort, filter, and collect fonts were killer features. Google Fonts, although not a direct competitor, is the most popular and available non-system font library. People use it not only because it\'s free, they use the service because it\'s easy to find, filter, and sort the fonts theatre available. Although, Google Fonts is not perfect with the font installation process remaining difficult.',
				'The main takeaways were to have powerful search, filter, and sorting with the ability to collect fonts for projects and have easy installation and maintenance.'
			]}
			imageData={data.competitiveAnalysis}
			altText={'A placeholder image for some app screens.'}
			link={{
				url:
					'https://aaronvince.com/static/4003--competitive-analysis-d32de912b348cfdd3ba880488781e568.pdf',
				label: 'Full Competitive Analysis'
			}}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'User Scenarios'}
			paragraphs={[
				'One of the important steps in User-centred design is user scenarios exploring the lives of potential users.',
				'Using these scenarios, I found that searching for novel fonts is an important aspect while also ensuring it is legal to display these fonts. Another scenario reveals that displaying a practical preview of the font in use is important. Finding complementary pairs of fonts is also an essential feature to keep people coming back. This can be achieved through aggregate data from Font Book across the globe or a panel of typography experts.'
			]}
			link={{
				url:
					'https://aaronvince.com/static/4003--user-scenarios-e45bf37d02a41d20622ef1bc7e1e3949.pdf',
				label: 'Full User Scenarios'
			}}
			imageData={data.userScenarios}
			altText={'User Scenarios for Font Book X.'}
			switch={true}
			contain={true}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Flowcharts'}
			paragraphs={[
				'Flowcharts are an invaluable tool to plan out the design of your app. Since I was redesigning an app rather than making one from whole cloth, I mapped out the current app workflow as well.',
				'The flowcharts revealed a solid roadmap to a MVP to future development of the product after version one.'
			]}
			link={{
				url:
					'https://aaronvince.com/static/4003--flowcharts-d94ce80c7f717fdd6655959afc7887f9.pdf',
				label: 'Full App Flowcharts'
			}}
			imageData={data.interactionMap}
			altText={'App Flowcharts.'}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Low Fidelity Wireframes'}
			paragraphs={[
				'After all of the research and analysis is done, I had a strong basis in which to make sketches of app screens and to draw interactions between them.'
			]}
			link={{
				label: 'Low Fidelity Wireframes',
				url: 'https://sketch.cloud/s/mOyxV'
			}}
			imageData={data.prototyping}
			altText={'Low Fidelity Wireframes.'}
			switch={true}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'Image & Graphic Assets'}
			paragraphs={[
				'Here is where we put the competitive analysis into action. I took all the research and incorporated that into the images and assets which would fit with the existing system iconography and be useful to the user.'
			]}
			link={{
				label: 'Full Image/Graphic Assets and Analysis ',
				url: 'https://sketch.cloud/s/mOyxV'
			}}
			imageData={data.imageAssets}
			altText={'App assets.'}
		/>
		<SVGSeparator />
		<TextAndImageBlock
			heading={'The Solution'}
			paragraphs={[
				'Please explore the app prototype below! There you will find the final app prototype displaying the new and improved functionality of Font Book X.',
				'The main sections include — ',
				'∙ the library where users can explore the fonts that they have installed with an option to filter and sort the fonts,',
				'∙ collections where users can find the collections of font they collected for projects or the collections automatically generated by classification or popularity,',
				'∙ the store where users can get free fonts or buy fonts for future projects or to fill in for a missing font,',
				'∙ the add font screen where users can explore a font\'s weights, pairings, examples, etc.,'
			]}
			imageData={data.solution}
			altText={'Showing the featured section of the font store.'}
			link={{
				label: 'Final Sketch Prototype',
				url: 'https://sketch.cloud/s/o1Rkx/ELgZ9PY/play'
			}}
			contain={true}
		/>
		<SVGSeparator />
		<Link to="/">← Back</Link>
	</Layout>
)

Castle.propTypes = {
	data: PropTypes.any.isRequired
}

export const pageQuery = graphql`
	query {
		heroImage: file(relativePath: { regex: "/Library — Font Details View/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		oldFontBook: file(relativePath: { regex: "/Font Book 9/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		competitiveAnalysis: file(
			relativePath: { regex: "/font-book-x--competitive-analysis/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		userScenarios: file(
			relativePath: { regex: "/font-book-x--user-scenarios/" }
		) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		interactionMap: file(relativePath: { regex: "/font-book-x--flowcharts/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		prototyping: file(relativePath: { regex: "/font-book-x--lo-fi/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		imageAssets: file(relativePath: { regex: "/font-book-x--image-assets/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		solution: file(relativePath: { regex: "/Featured/" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`

export default Castle
